@if (!territories?.length) {
  <span class="color-basic-600 fst-italic text-truncate">{{ "tenders.not-detected-m" | translate }}</span>
  {{territories}}
} @else {
  @if (territories.length < maximumShownTerritories) {
    <div class="text-truncate"
         [ngbTooltip]="tenderTerritoriesTextService.getString(territories)"
         tooltipClass="tender-territories-tooltip"
         placement="bottom auto"
         [openDelay]="500"
         overflowTooltip
    >
      {{ tenderTerritoriesTextService.getCompactCodeString(territories) }}
    </div>
  } @else {
    <div class="territories-container text-truncate"
         [ngbTooltip]="tenderTerritoriesTextService.getString(territories)"
         tooltipClass="tender-territories-tooltip"
         placement="bottom auto"
         [openDelay]="500"
    >
      ({{ tenderTerritoriesTextService.getCompactString(territories, reducedShownTerritories, true) }})
    </div>
  }
}

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirmation-with-checkbox',
  templateUrl: './modal-confirmation-with-checkbox.html',
  styleUrls: ['./modal-confirmation-with-checkbox.scss']
})
export class ModalConfirmationWithCheckbox {
  @Input() translationFileSection!: string;
  @Input() updateMode!: boolean;
  @Input() deleteMode!: boolean;
  @Input() onValidateAction!: () => void;
  @Input() affectedItemsCount!: number;

  checked = false;

  constructor(
    private activeModal: NgbActiveModal,
  ) {

  }

  onCheckedChange() {
    this.checked = !this.checked;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }


  confirm() {
    if (this.checked) {
      this.activeModal.close(true);
      this.onValidateAction();

    }
  }

}

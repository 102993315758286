<div class="d-flex no-select tender-status-selector-main-container {{size}}"
     [style.background-color]="item?.value?.color ? 'color-mix(in srgb,' + item?.value?.color + ' 20%, transparent)' : 'var(--basic-100)'"
     [ngClass]="{'ex-shadow': useShadow}">
  <div
    (click)="quickQualification($event)"
    class="d-flex flex-center-start status-selected"
    [style.color]="item?.value?.color"
    >
    <div *ngIf="item?.value?.id" class="text-truncate">
      {{ item?.value?.displayName }}
    </div>
    <div *ngIf="!item?.value?.id" class="d-flex w-100 align-items-center color-basic-600 gap-1">
      <i class="ph ph-target"></i>
      <div class="font-12px fw-600 text-truncate">{{'tenders.status.qualify' | translate}}</div>
    </div>
  </div>


  <ex-dropdown
    #statusDropdown
    [items]="tendersModuleService.statusItems"
    class="{{item?.value?.displayName ? item?.value?.displayName : 'no-status-selected'}}"
    chevronVisibility="hover"
    [headerBackgroundColor]="item?.value?.color ? 'color-mix(in srgb,' + item?.value?.color + ' 20%, transparent)' : 'var(--basic-100)'"
    [headerColor]=item?.value?.color
    [forceItem$]="forceItem$"
    [forceToggle$]="forceToggle$"
    [size]="size"
    [placement]="placement"
    noInput
    noBorder
  (change)="updateAnnotationValue($event)"
  (dropdownToggle)="onDropdownToggle($event)"
  container="body"
  [overlayPositions]="[
    {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
    {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
    {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
    {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'}
  ]"
  paddedItemsContainer
>
    <ng-template exDropdownDisplay/>

    <ng-template exDropdownContent>
      <app-tender-status-list [item]="item"
                              [defaultItems]="tendersModuleService.defaultStatusItems"
                              [customItems]="tendersModuleService.customStatusItems"
                              (itemSelect)="$event == null ? removeStatus() : statusDropdown.select($event)"
                              showNone
                              editable
      />
    </ng-template>
  </ex-dropdown>


  <ng-template #toast let-toast="toast" let-toastService="toastService">
    <div class="position-relative toast-container d-flex w-100 h-100">
      <div class="d-flex">
        <div class="d-flex toast-content">
          <div class="d-flex h-100 flex-grow-0">
            <img class="align-items-center justify-content-center"
                 src="../../../../assets/images/icon-alert-triangle-white.svg"
                 alt="icon-alert">
          </div>
          <div class="flex-grow-1 align-items-center">
            <p class="m-0 fw-bold">{{ 'tenders.status.toast-content' | translate:({name: item?.value?.displayName})}}</p>
          </div>
        </div>
        <i class="ph ph-x" (click)="toastService.remove(toast)"></i>
      </div>
    </div>
  </ng-template>

</div>

import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TendersSearchService } from '../../services/tenders-search.service';
import { TenderTerritoriesTextService } from '../../services/helpers/tender-territories-text.service';
import { hasNonZeroTime } from '../../services/helpers/date-formater';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { Tender } from '../../models/tender';
import { firstValueFrom } from 'rxjs';
import { TendersModuleService } from "../../services/tenders-module.service";
import {isGenerationHourBygone, MarketWatchDayOfWeek} from "../../models/market-watch";
import {
  findTheoricClosestWatchDate,
  isSameDay,
  isSamePeriod,
  toDayPeriod
} from "../../../shared/helpers/date-helper";
import { ApiTenderService } from "../../services/api/api-tender.service";
import { getSanitizedString } from "../../services/helpers/string-helper";
import { StatusItem } from "../../models/tender-status";
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TendersExportService } from '../../services/tenders-export.service';
import { OVERLAY_POSITIONS } from "../../../common-explain/components/ex-dropdown-v2/ex-dropdown-v2.component";
import { TenderTypesEnum } from '../../models/tender-search-body';

const days = require('../../../shared/helpers/days.json') as MarketWatchDayOfWeek[];

@Component({
  selector: 'app-tenders-list',
  templateUrl: './tenders-list.component.html',
  styleUrls: ['./tenders-list.component.scss']
})
export class TendersListComponent implements OnChanges {
  protected readonly hasNonZeroTime = hasNonZeroTime;

  @Input({required: true}) tendersSearchService!: TendersSearchService;
  @Input() tendersExportService?: TendersExportService;
  @Input() inWatches: boolean = false;
  @Input() isFirstWatch: boolean = false;
  @Input() newParamsNotActiveYet: boolean = false;
  @Input({transform: booleanAttribute}) inBoardView = false;
  @Output() addWatchUsersClicked = new EventEmitter<boolean>();
  @ViewChild("tendersList") tendersList?: CdkVirtualScrollViewport;

  hoveredTenderId: string | null = null;
  tenderNoteEditorOpenings: {[key: string]: boolean} = {};

  emptyStates = [
    {error: 'no-text', icon: 'add-text'},                                       // pas de texte de recherche
    {error: 'invalid-topics', icon: 'warning-circle'},                          // veille obsolète
    {error: 'no-topic', icon: 'add-message'},                                   // pas de thème
    {error: 'no-text-or-topic', icon: 'tender-search'},                         // pas de texte ni de thème
    {error: 'no-period', icon: 'no-calendar'},                                  // pas de période
    {error: 'no-perimeter', icon: 'close-circle-fill'},                         // pas de périmètre
    {error: 'no-type', icon: 'close-circle-fill'},                              // pas de type
    {error: 'watch-intro', icon: 'binocular-plus'},                             // premiere veille
    {error: 'watch-no-results', icon: 'close-circle-fill'},                     // veille ø résultat
    {error: 'preview-watch-no-results', icon: 'close-circle-fill'},             // veille ø résultat
    {error: 'first-day-watch-not-ready', icon: 'hourglass-up'},                 // veille nouvelle avant premier lot
    {error: 'today-watch-not-ready', icon: 'hourglass-up'},                     // veille existante consultée entre 00h et 06h
    {error: 'no-results', icon: 'close-circle-fill'},                           // ø résultat
    {error: 'no-qualified-tenders', icon: 'close-circle-fill'},                 // ø résultat en go/no-go
  ];

  constructor(
    protected tenderTerritoriesTextService: TenderTerritoriesTextService,
    protected tendersModuleService: TendersModuleService,
    protected apiTendersService: ApiTenderService,
    private userTrackerService: UserTrackerService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tendersSearchService']) {
      this.scrollToTop();
      // Definition du searchService comme le boardSearchService du module service.
      if (this.inBoardView) this.tendersModuleService.boardSearchService = this.tendersSearchService;
    }
  }

  get currentPage() {
    return (this.tendersSearchService.offset ?? 0) / this.tendersSearchService.limit + 1;
  }

  get loadingMore() {
    if (this.tendersSearchService.inSearch && !this.tendersSearchService.offset) {
      this.scrollToTop();
    }
    return !!(this.tendersSearchService.inSearch && this.tendersSearchService.offset);
  }

  loadMore() {
    if (this.tendersSearchService.lastPageReached || this.tendersSearchService.inSearch) return;
    this.tendersSearchService.page = this.tendersSearchService.loadedPage + 1;
  }

  scrollToTop() {
    this.tendersList?.scrollTo({top: 0, behavior: 'smooth'});
  }

  search() {
    this.tendersSearchService.search();
  }

  getFirstPreSearchError(): string {
    if (this.inBoardView) return '';
    if (this.tendersSearchService.tenders) return '';
    if (!this.tendersSearchService.selectedTopics?.length) {
      if (this.tendersSearchService.isObsolete) return 'invalid-topics';
      if (this.isFirstWatch) return 'watch-intro';
      return 'no-text-or-topic';
    }
    if ((!this.tendersSearchService.selectedIndexationPeriod?.from || !this.tendersSearchService.selectedIndexationPeriod?.to) &&
      !(this.tendersSearchService.selectedEstimatedEndPeriod?.from || this.tendersSearchService.selectedEstimatedEndPeriod?.to)) return 'no-period';
    if (!this.tendersSearchService.selectedTerritories?.length) return 'no-perimeter';
    if (this.tendersSearchService.tenderTypes && !this.tendersSearchService.tenderTypes?.length) return 'no-type';
    return ''
  }

  getFirstPostSearchError(): string {
    if (this.inWatches) {
      if (this.tendersSearchService.viewMode === 'preview') { return 'preview-watch-no-results';}
      const now = new Date();
      if (isSamePeriod(this.tendersModuleService.currentManager.service.selectedIndexationPeriod, toDayPeriod(now)) && !isGenerationHourBygone()) return 'today-watch-not-ready';
      if (isSameDay(new Date(this.tendersModuleService.currentManager.marketWatch?.createdAt ?? ''), now) && isGenerationHourBygone()) return 'first-day-watch-not-ready';
      return 'watch-no-results';
    }
    return 'no-results';
  }

  hasScroll() {
    if (this.tendersList)
      return this.tendersList.elementRef.nativeElement.offsetHeight < this.tendersList.elementRef.nativeElement.scrollHeight;
    else return false;
  }

  isToday(date: Date): boolean {
    return date.toDateString() === new Date().toDateString();
  }

  trackClickedTender(tender: Tender) {
    firstValueFrom(this.userTrackerService.track({
      event_type: EventTypeName.TENDER_CLICKED,
      event_timestamp: (new Date()).toISOString(),
      ...this.userTrackerService.buildBasicUserInformations(),
      ...tender,
      filters: this.tendersSearchService.filters,
      rank: (this.tendersSearchService.tenders?.indexOf(tender) ?? 0) + 1,
      score: tender.score ?? 0,
    }));
  }

  getIcon(error: string) {
    return this.emptyStates.find(e => e.error === error)?.icon ?? 'close-circle-fill';
  }

  addMarketWatchUsers() {
    this.addWatchUsersClicked.emit(true);
  }

  //region **status selector methods**
  isEditorOpened(event: {id: string, isOpen: boolean}) {
    this.tenderNoteEditorOpenings[event.id] = event.isOpen;
  }

  noteChange(tender: Tender, note: string) {
    tender.note = note;
    this.tendersModuleService.updateTenderInRegisteredServices(tender.id, {note});
  }

  statusChange(tender: Tender, status?: StatusItem) {
    if (status === undefined) {
      this.tendersSearchService.statusCounts?.length && this.tendersSearchService.updateStatusCount(tender.id, {from: tender.statusId});
    } else {
      const previousStatusId = tender.statusId;
      const newStatusId = status.id;
      tender.statusId = status.id!;
      this.tendersSearchService.statusCounts?.length && this.tendersSearchService.updateStatusCount(tender.id, {from: previousStatusId, to: newStatusId!});
    }
    this.tendersModuleService.updateTenderInRegisteredServices(tender.id, {statusId: status?.id ?? undefined});
  }

  //endregion

  findNextWatchDay() {
    const date = findTheoricClosestWatchDate(new Date(), this.tendersModuleService.currentManager.marketWatch?.dayOfWeek, true)
    return days.find(d => d.index === date.getDay())?.value
  }

  identifyTender(index: number, tender: Tender) {
    return tender.id
  }

  protected readonly getSanitizedString = getSanitizedString;
  protected readonly OVERLAY_POSITIONS = OVERLAY_POSITIONS;
  protected readonly TenderTypesEnum = TenderTypesEnum;
}

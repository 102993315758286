import { Injectable } from '@angular/core';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';


export interface DbTenderAnnotation {
  group_account_id?: number,
  id: number,
  note?: string,
  status_id?: number
  assigned_user_id?: number,
  tender_uid: string,
  created_at?: string,
  updated_at?: string
}

@Injectable({
  providedIn: 'root'
})
export class ApiAnnotationService {
  constructor(private httpHeadersService: HttpHeadersService,
              private http: HttpClient) {
  }

  addAnnotation(body: {tender_uid: string, status_id?: number, note?: string, assigned_user_id?: number}) {
    return firstValueFrom(this.http
      .put<{
        tender_annotation_id: number,
        message: string
      }>(
        `${environment.explainApiUrl}tender_annotations`,
        JSON.stringify(body),
        this.httpHeadersService.httpHeaders
      )
    )
  }

  updateAnnotation(body: {status_id?: number | null, note?: string, assigned_user_id?: number | null, id: number}) {
    return firstValueFrom(this.http
      .patch<{
        tender_annotation_id: number,
        message: string
      }>(
        `${environment.explainApiUrl}tender_annotations`,
        JSON.stringify(body),
        this.httpHeadersService.httpHeaders
      )
    )
  }

  getAnnotation(tenderUid: string): Promise<DbTenderAnnotation | undefined> {
    const payload = {
      fields: ["id", "tender_uid", "status_id", "note", "assigned_user_id"],
      filters: {
        tender_uid: tenderUid
      },
      limit: 1,
      offset: 0
    }
    return firstValueFrom(this.http
      .post<{data: DbTenderAnnotation[]}>(
        `${environment.explainApiUrl}tender_annotations`,
        payload,
        this.httpHeadersService.httpHeaders,
      )
    ).then(res => res.data[0]);
  }

  getNote(tenderUid: string): Promise<{note:string, annotationId: number}> {
    return this.getAnnotation(tenderUid).then((res) => {
      return {note: res?.note ?? '', annotationId: res?.id ?? 0}
    });
  }

  createNote(id: string, note: string) {
    return this.addAnnotation({tender_uid: id, note});
  }

  updateNote(annotationId: number, note: string) {
    return this.updateAnnotation({note: note, id: annotationId});
  }

  getAnnotationsCount(body: {statusId: number}) {
    const payload = {
      fields: ["id"],
      filters: {
        status_id: body.statusId
      },
      limit: 1,
      offset: 0, paginate: true
    }
    return firstValueFrom(this.http
      .post<{data: DbTenderAnnotation[], pagination: {total_item_count: number, total_page_count: number}}>(
        `${environment.explainApiUrl}tender_annotations`,
        payload,
        this.httpHeadersService.httpHeaders,
      )
    ).then(res => res.pagination.total_item_count);
  }
}

import { AfterViewInit, booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18nService } from './custom-datepicker-i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { Period, TendersSearchService, FilteringPeriodTypeEnum } from '../../services/tenders-search.service';
import { environment } from "../../../../environments/environment";

export interface DbPeriod {
  from: NgbDate | null;
  to: NgbDate | null;
}

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}]
})
export class FilterDateComponent implements AfterViewInit {
  FilteringPeriodTypeEnum = FilteringPeriodTypeEnum;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  maxDate: NgbDate;
  startDate: NgbDate;
  inputText = "";

  @Input() defaultPeriod?: DbPeriod;
  @Input({required: true}) tenderSearchService!: TendersSearchService;
  @Output() periodSelected = new EventEmitter<{ period: Period | undefined, field: "indexation_period" | "estimated_end_period" }>();
  filteringPeriodType = FilteringPeriodTypeEnum.INDEXATION;
  cachedDates: { [key: string]: DbPeriod } = {};
  @Input({transform: booleanAttribute}) displayedEstimatedEndPeriod= false;
  showPastille = true;
  allowSearchByEstimatedEndPeriod = (environment as any)['allowSearchByEstimatedEndPeriod'];

  constructor(calendar: NgbCalendar, private translateService: TranslateService) {
    const today = calendar.getToday();
    this.fromDate = calendar.getPrev(today, 'd', 6);
    this.toDate = today
    this.maxDate = today
    this.startDate = calendar.getPrev(today, 'm', 1);
    this.updatePeriodText()
    this.cachedDates[FilteringPeriodTypeEnum.INDEXATION] = {from: this.fromDate, to: this.toDate};
    this.cachedDates[FilteringPeriodTypeEnum.ESTIMATED_END] = {from: today, to: calendar.getNext(today, 'y', 4)};
  }

  ngAfterViewInit() {
    if (this.defaultPeriod) {
      this.fromDate = this.defaultPeriod.from;
      this.toDate = this.defaultPeriod.to;
      this.updatePeriodText();
    }
    this.updatePeriod();
    this.showPastille = !localStorage.getItem('new-filter-estimated-end-period-selector-feature-seen-2025_01_25');
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.updatePeriod();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.updatePeriodText();
  }

  updatePeriod() {
    this.periodSelected.emit({
      period: (this.fromDate && this.toDate) ? {
        from: this.getIsoStringFromNgbDate(this.fromDate),
        to: this.getIsoStringFromNgbDate(this.toDate)
      } : undefined,
      field: this.filteringPeriodType
    });
    this.updateCaches();
    this.updatePeriodText();
  }

  updateCaches() {
    this.cachedDates[this.filteringPeriodType] = {from: this.fromDate, to: this.toDate};
  }

  getIsoStringFromNgbDate(date: NgbDate): string {
    return `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  getReadableDate(date: NgbDate | null): string {
    return getFormattedNgbDate(date, 'day/month/year');
  }

  updatePeriodText() {
    this.inputText = this.getReadableDate(this.fromDate) + (this.toDate ? ' • ' + this.getReadableDate(this.toDate) : '')
  }

  exDropdownChangeMethodTriggered(event: any) {
    if (event === null) {
      this.fromDate = null;
      this.toDate = null;
      this.updatePeriod();
    }
  }

  dropdownToggled(state: boolean) {
    if (!state && !this.fromDate || !this.toDate) {
      this.inputText = this.translateService.instant('tenders.invalid-period')
      this.updatePeriod()
    }
  }

  setDatePeriodMode(mode: FilteringPeriodTypeEnum, event: MouseEvent) {
    // caching values from previous mode.
    if (this.filteringPeriodType !== mode) ({ from: this.fromDate, to: this.toDate } = this.cachedDates[mode]);
    event.preventDefault();
    event.stopPropagation();
    this.filteringPeriodType = mode;
    this.updatePeriod();
  }

  managePastille() {
    this.showPastille = false;
    localStorage.setItem('new-filter-estimated-end-period-selector-feature-seen-2025_01_25', 'true');
  }
}

/** Format an NgbDate into the specified form : use worlds day, month and year in lower case to describe wanted format
 *  ex: day/month/year OR year-month-day */
export function getFormattedNgbDate(date: NgbDate | null, format: string): string {
  if (date === null) return '';
  return format.replace('day', date.day.toString().padStart(2, '0'))
    .replace('month', date.month.toString().padStart(2, '0'))
    .replace('year', `${date.year}`);
}


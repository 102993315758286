<!-- main app container -->
<div class="card m-3">
  <div class="card-body">
    <form [formGroup]="form"
          (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-6 mb-3">
          <label class="form-label">Sélectionnez une date
            <input type="date" formControlName="date"
                   maxlength="10"
                   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.date.errors }"/>
          </label>
          <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
            <div *ngIf="f.date.errors?.['required']">Sélectionnez une date</div>
            <div *ngIf="f.date.errors?.['pattern']">La date doit être au format JJ/MM/AAAA</div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-primary me-2">Submit</button>
        <button class="btn btn-secondary" type="reset" (click)="onReset()">Reset</button>
      </div>
    </form>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: 'ex-date-selector',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgIf
  ],
  templateUrl: './ex-date-selector.component.html',
  styleUrl: './ex-date-selector.component.scss'
})
export class ExDateSelectorComponent {

  @Input() date?: Date;
  form!: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      date: ['', [
        Validators.required,
        // Valide la date au format jj-mm-aaaa avec une expression régulière
        Validators.pattern(/^\d{4}\-(0[1-9]|1[1-2])\-(0[1-9]|[12][0-9]|3[01])$/)
      ]]
    });
  }

  get f() {
    return this.form.controls as {date: AbstractControl<any, any>};
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }

}
